export default {
	configFields(model) {
		return {
			id: 'configFields',
			selectionType: 'general',
			label: 'modal.customfields.configFields',
			functionality: 'ACTION_CUSTOM_ATTRIBUTE',
			checkAvailability: function () {
				return true;
			},
			runAction: function () {
				console.log('configFields', JSON.stringify(model, null, 5));
				let row = {};
				row.model = model;
				row.isAction = true;
				this.$puiEvents.$emit('pui-modalDialogForm-configFields-customFields-show', row);
			}
		};
	}
};
